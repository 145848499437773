import { StyleSystemProps } from './Elements/Elements';

export default (
  option: SectionPaddingOption | undefined,
  blade:
    | 'bladeHeroAnimation'
    | 'bladeEnablement'
    | 'bladeTrustedBy'
    | 'bladeFourFeatures'
    | 'bladeThreeFeatures'
    | 'bladeImageText'
    | 'bladeKeyFeatures'
    | 'bladeQuote'
    | 'bladeAccordion'
    | 'bladeRichTextColumnGroup'
    | 'bladePricingPlans'
    | 'bladeResourceGrid'
    | 'bladeForms'
    | 'bladeTable'
    | 'bladePlansMatrix'
    | 'bladeAddOns'
    | 'navNavigation'
    | 'bladeNetworkStats'
    | 'bladeTabs'
): Partial<Pick<StyleSystemProps, 'paddingTop' | 'paddingBottom'>> => {
  switch (option) {
    case BOTTOM_PADDING:
      if (blade === 'bladeRichTextColumnGroup') {
        return { paddingBottom: [7, 7, 7, 11] };
      } else if (blade === 'bladeEnablement') {
        // return 'pb6-ns pb7';
        return { paddingBottom: [7, 6] };
      } else if (blade === 'bladeTrustedBy') {
        // return 'pb9-ns pb7';
        return { paddingBottom: [7, 9] };
      } else if (blade === 'bladeQuote') {
        // return 'pb10-ns pb7';
        return { paddingBottom: [7, 10] };
      } else if (blade === 'bladePricingPlans') {
        return { paddingBottom: [7, 10] };
      } else if (blade === 'bladeResourceGrid') {
        return { paddingBottom: [7, 7, 7, 10] };
      } else if (blade === 'bladeForms') {
        return { paddingBottom: [7, 7, 7, 10] };
      } else if (blade === 'bladeTable') {
        return { paddingBottom: [7, 7, 7, 10] };
      } else if (blade === 'bladeFourFeatures' || blade === 'bladeThreeFeatures') {
        return { paddingBottom: [7, 6] };
      } else if (blade === 'navNavigation') {
        return { paddingBottom: [7, 0] };
      } else {
        // return 'pb10-ns pb7';
        return { paddingBottom: [7, 10] };
      }
    case TOP_PADDING:
      if (blade === 'bladeRichTextColumnGroup') {
        return { paddingTop: [4, 4, 4, 10] };
      } else if (blade === 'bladeEnablement') {
        // return 'pt6-ns pt4';
        return { paddingTop: [4, 6] };
      } else if (blade === 'bladeTrustedBy') {
        // return 'pt9-ns pt4';
        return { paddingTop: [4, 9] };
      } else if (blade === 'bladeQuote') {
        // return 'pt11-ns pt7';
        return { paddingTop: [7, 11] };
      } else if (blade === 'bladePricingPlans') {
        return { paddingTop: [4, 7] };
      } else if (blade === 'bladePlansMatrix') {
        return { paddingTop: [4, 7] };
      } else if (blade === 'bladeTabs') {
        return { paddingTop: [4, 7] };
      } else if ('bladeResourceGrid') {
        return { paddingTop: [4, 9] };
      } else if ('bladeForms') {
        return { paddingTop: [4, 9] };
      } else if ('bladeTable') {
        return { paddingTop: [4, 9] };
      } else if (blade === 'navNavigation') {
        return { paddingTop: [5, 7] };
      } else {
        // return 'pt9-ns pt4';
        return { paddingTop: [4, 9] };
      }
    case NO_PADDING:
      return {};
    default:
      if (blade === 'bladeRichTextColumnGroup') {
        return { paddingTop: [4, 4, 4, 10], paddingBottom: [7, 7, 7, 11] };
      } else if (blade === 'bladeEnablement') {
        // return 'pb6-ns pt6-ns pb7 pt4';
        return { paddingTop: [4, 6], paddingBottom: [7, 6] };
      } else if (blade === 'bladeTrustedBy') {
        // return 'pb9-ns  pb9-ns pb7 pt4';
        return { paddingTop: [4, 9], paddingBottom: [7, 9] };
      } else if (blade === 'bladeQuote') {
        // return 'pb10-ns pt11-ns pa7';
        return { paddingTop: [7, 11], paddingBottom: [7, 10] };
      } else if (blade === 'bladePricingPlans') {
        return { paddingTop: [4, 7], paddingBottom: [7, 9] };
      } else if (blade === 'bladeForms') {
        // return 'pb10-ns pt11-ns pa7';
        return { paddingTop: [7, 10], paddingBottom: [4, 9] };
      } else if (blade === 'bladeTable') {
        // return 'pb10-ns pt11-ns pa7';
        return { paddingTop: [7, 10], paddingBottom: [4, 9] };
      } else if (blade === 'bladeResourceGrid') {
        return { paddingTop: [7, 10], paddingBottom: [4, 9] };
      } else if (blade === 'bladeFourFeatures' || blade === 'bladeThreeFeatures') {
        return { paddingTop: [4, 9], paddingBottom: [7, 6] };
      } else if (blade === 'navNavigation') {
        return { paddingTop: [5, 7], paddingBottom: [7, 0] };
      } else if (blade === 'bladePlansMatrix') {
        return { paddingTop: [4, 7], paddingBottom: [7, 10] };
      } else if (blade === 'bladeTabs') {
        return { paddingTop: [4, 7], paddingBottom: [7, 10] };
      } else {
        // return 'pb10-ns pt9-ns pb7 pt4';
        return { paddingTop: [4, 9], paddingBottom: [7, 10] };
      }
  }
};

export const BOTTOM_PADDING = 'tile--no-top-padding';
export const TOP_PADDING = 'tile--no-bottom-padding';
export const NO_PADDING = 'tile--no-top-bottom-padding';

export type SectionPaddingOption =
  | typeof BOTTOM_PADDING
  | typeof TOP_PADDING
  | typeof NO_PADDING
  | null;
